
import CloudFun, { Condition, defineComponent, Operator, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";
import axios from "axios";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const user = CloudFun.current?.user;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const isExporting = ref(false);

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: "user",
      title: "訂單",
      multiselect: false,
      // canCreate: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "訂單清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "訂單清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: 'Time', title: '時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => CloudFun.utils.formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') },
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true },
        // { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "Time", order: 1 });
          return model.dispatch("order/query", params);
        } : undefined,
        queryAll: model ? () => model.dispatch("order/query") : undefined,
        save: model ? (params) => model.dispatch("order/save", params) : undefined,
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Time", title: "下單時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", disabled: true } } },
        { field: "Number", title: "編號", span: 12, itemRender: { name: "$input", props: { placeholder: "未輸入則系統自動產生" } } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "MemberId", title: "會員", span: 12, slots: { default: "column-member-id" } },
        { field: "Type", title: "方案", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "PrintType", title: "印製類型", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderPrintType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Amount", title: "金額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { title: "訂單項目", span: 24, titleAlign: "left" },
        { span: 24, slots: { default: "column-items" } },
        { title: "附件", span: 24, titleAlign: "left" },
        { span: 24, slots: { default: "column-design-records" } },
        { title: "寄件人資訊", span: 24, titleWidth: "100", titleAlign: "left" },
        { field: "Shipping.SenderName", title: "姓名", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.SenderEmail", title: "Email", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.SenderMobilePhone", title: "電話", span: 8, itemRender: { name: "$input" } },
        { title: "收件人資訊", span: 24, titleWidth: "100", titleAlign: "left" },
        { field: "Shipping.ReceiverName", title: "姓名", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.ReceiverEmail", title: "Email", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.ReceiverMobilePhone", title: "電話", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.ReceiverAddress", title: "地址", span: 24, itemRender: { name: "$input" } },
        { field: "Shipping.Time", title: "期望交貨時間", titleWidth: "100", span: 12, itemRender: { name: "$input", props: { type: "date" } } }
      ],
      rules: {
        MemberId: [{ required: true }],
      }
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇會員",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("member/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("member/query", params), // eslint-disable-line
      }
    };

    const onExport = () => {
      isExporting.value = true;
      // 使用此種方式會有Uncaught (in promise) undefined的問題
      // model!.dispatch("order/export", { orderId: grid.value.editingRow.Id })
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/Orders/Export',
        { orderId: grid.value.editingRow.Id },
        { responseType: "blob", headers: { Authorization: 'Basic ' + btoa(`${user?.Id}:${user?.Token}`) } })
        .then((response: any) => {
          window.open(window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] })));
        }).finally(() => {
          isExporting.value = false;
        })
    }

    return {
      grid,
      isExporting,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      onExport,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("order/find", row.Id)
        : undefined;

      if (entity) {
        Object.assign(row, entity);
        const itemIds = row.Items.filter((e: any) => e.ProductItemSizeId).map((e: any) => e.ProductItemSizeId);
        const items = await this.$model.dispatch("productItemSize/query", { condition: new Condition("Id", Operator.In, itemIds) });
        row.Items.filter((e: any) => e.ProductItemSizeId).forEach((e: any) => {
          e.ProductItemSizeName = items.find((i: any) => i.Id === e.ProductItemSizeId).Size.Name;
        });
      }
      callback();
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    download(row: any) {
      window.open(row.Uri)
    }
  }
});
