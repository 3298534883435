<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">訂單管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <!-- <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="text" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" @keyup.enter="grid.refresh" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
          <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
          <!-- </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit" @removeSelectedRows="hideBatchDropDown()">
          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="submit">
              <template #column-member-id="{ data }">
                <select-box v-bind="selectorOptions" v-model="data.MemberId" />
              </template>
              <template #column-items="{ data }">
                <vxe-table stripe resizable border round :data="data?.Items?.filter(e => e.ProductItemSizeName)"
                  :edit-config="{ trigger: 'click', mode: 'row' }">
                  <vxe-table-column field="ProductItemSizeName" title="尺寸"></vxe-table-column>
                  <vxe-table-column field="Name" title="客製化姓名"></vxe-table-column>
                  <vxe-table-column field="Price" title="價格">
                    <template #edit="{ row }">
                      <vxe-input type="number" v-model="row.Price" />
                    </template>
                  </vxe-table-column>
                  <vxe-table-column field="Quantity" title="數量">
                    <template #edit="{ row }">
                      <vxe-input type="number" v-model="row.Quantity" />
                    </template>
                  </vxe-table-column>
                </vxe-table>
                <vxe-table stripe resizable border round :data="data?.Items?.filter(e => !e.ProductItemSizeName)"
                  :edit-config="{ trigger: 'click', mode: 'row' }">
                  <vxe-table-column field="Name" title="其他費用"></vxe-table-column>
                  <vxe-table-column field="Price" title="金額">
                    <template #edit="{ row }">
                      <vxe-input type="number" v-model="row.Price" />
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </template>
              <template #column-design-records="{ data }">
                <vxe-table stripe resizable border round :data="data.DesignRecords?.[0]?.Files">
                  <vxe-table-column field="Name" title="ai檔名"></vxe-table-column>
                  <vxe-table-column title="下載">
                    <template #default="{ row }">
                      <vxe-button @click="download(row)">下載</vxe-button>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <div class="flex justify-between">
              <vxe-button type="button" status="success" content="匯出訂單" @click="onExport" :loading="isExporting">
              </vxe-button>
              <div>
                <vxe-button type="submit" status="primary" content="確認"
                  @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
                </vxe-button>
                <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
              </div>
            </div>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" class="input input--switch border mt-4 ml-2" :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)" />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input v-model.trim="option.data" :disabled="!option.checked" type="text"
                  class="input pl-12 w-full border col-span-4" placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)" />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";
import axios from "axios";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const user = CloudFun.current?.user;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const isExporting = ref(false);

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: "user",
      title: "訂單",
      multiselect: false,
      // canCreate: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "訂單清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "訂單清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: 'Time', title: '時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => CloudFun.utils.formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') },
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true },
        // { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "Time", order: 1 });
          return model.dispatch("order/query", params);
        } : undefined,
        queryAll: model ? () => model.dispatch("order/query") : undefined,
        save: model ? (params) => model.dispatch("order/save", params) : undefined,
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Time", title: "下單時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", disabled: true } } },
        { field: "Number", title: "編號", span: 12, itemRender: { name: "$input", props: { placeholder: "未輸入則系統自動產生" } } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "MemberId", title: "會員", span: 12, slots: { default: "column-member-id" } },
        { field: "Type", title: "方案", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "PrintType", title: "印製類型", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderPrintType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Amount", title: "金額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { title: "訂單項目", span: 24, titleAlign: "left" },
        { span: 24, slots: { default: "column-items" } },
        { title: "附件", span: 24, titleAlign: "left" },
        { span: 24, slots: { default: "column-design-records" } },
        { title: "寄件人資訊", span: 24, titleWidth: "100", titleAlign: "left" },
        { field: "Shipping.SenderName", title: "姓名", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.SenderEmail", title: "Email", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.SenderMobilePhone", title: "電話", span: 8, itemRender: { name: "$input" } },
        { title: "收件人資訊", span: 24, titleWidth: "100", titleAlign: "left" },
        { field: "Shipping.ReceiverName", title: "姓名", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.ReceiverEmail", title: "Email", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.ReceiverMobilePhone", title: "電話", span: 8, itemRender: { name: "$input" } },
        { field: "Shipping.ReceiverAddress", title: "地址", span: 24, itemRender: { name: "$input" } },
        { field: "Shipping.Time", title: "期望交貨時間", titleWidth: "100", span: 12, itemRender: { name: "$input", props: { type: "date" } } }
      ],
      rules: {
        MemberId: [{ required: true }],
      }
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇會員",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("member/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("member/query", params), // eslint-disable-line
      }
    };

    const onExport = () => {
      isExporting.value = true;
      // 使用此種方式會有Uncaught (in promise) undefined的問題
      // model!.dispatch("order/export", { orderId: grid.value.editingRow.Id })
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/Orders/Export',
        { orderId: grid.value.editingRow.Id },
        { responseType: "blob", headers: { Authorization: 'Basic ' + btoa(`${user?.Id}:${user?.Token}`) } })
        .then((response: any) => {
          window.open(window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] })));
        }).finally(() => {
          isExporting.value = false;
        })
    }

    return {
      grid,
      isExporting,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      onExport,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("order/find", row.Id)
        : undefined;

      if (entity) {
        Object.assign(row, entity);
        const itemIds = row.Items.filter((e: any) => e.ProductItemSizeId).map((e: any) => e.ProductItemSizeId);
        const items = await this.$model.dispatch("productItemSize/query", { condition: new Condition("Id", Operator.In, itemIds) });
        row.Items.filter((e: any) => e.ProductItemSizeId).forEach((e: any) => {
          e.ProductItemSizeName = items.find((i: any) => i.Id === e.ProductItemSizeId).Size.Name;
        });
      }
      callback();
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    download(row: any) {
      window.open(row.Uri)
    }
  }
});
</script>
